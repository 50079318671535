import AppLandingPageJsx from "./app-landing-page";
import "./App.css";

function App() {
  return (
    <AppLandingPageJsx />
  );
}

export default App;
