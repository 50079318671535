import React, { useRef, useState, useEffect } from "react";
import { RiAppleFill } from "react-icons/ri";
import { FaFacebook, FaRegCommentDots } from "react-icons/fa";
import { CgSearchFound } from "react-icons/cg";
import { PiInstagramLogoFill } from "react-icons/pi";
import { DiAndroid } from "react-icons/di";
import { ChevronUp } from "lucide-react";
import { FaCheck } from "react-icons/fa";
import { MdOutlineAddTask } from "react-icons/md";
import { LuCalendarCheck2 } from "react-icons/lu";
import { SlCallOut } from "react-icons/sl";
import emailjs from "emailjs-com";
import LogoPic from "./images/114.png";
import LogoText from "./images/hlogo.png";
import BackgroundPic from "./images/bg.jpg";
import FeaturePic from "./images/myapp.png";
import { ChevronDown } from "lucide-react"; // or any other arrow icon

// Define the Logo component
function Logo() {
  return (
    <img
      src = {LogoPic}
      alt="Tradesmen Factory"
      width={80}
      height={80}
      className="max-w-full h-auto"
    />
  );
}


function TextLogo() {
  return (
    <img
      src = {LogoText}
      alt="Tradesmen Factory"
      width={250}
      height={80}
      className="max-w-full h-auto"
    />
  );
}
   

function AppLandingPageJsx() {
  const aboutRef = useRef(null);
  const featuresRef = useRef(null);
  const downloadRef = useRef(null);
  const packagesRef = useRef(null);
  const faqRef = useRef(null);
  const contactRef = useRef(null);
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);
  const [isSending, setIsSending] = useState(false);
  const [formStatus, setFormStatus] = React.useState('');
  const [formData, setFormData] = useState({
    from_name: "",
    reply_to: "",
    message: "",
  });
  

  const menuRef = useRef(null);

  const scrollToSection = (elementRef) => {
    if (menuOpen) {
      setMenuOpen(false);
    }
    window.scrollTo({
      top: elementRef.current.offsetTop,
      behavior: "smooth",
    });
  };

  function FAQItem({ question, answer }) {
    const [isOpen, setIsOpen] = useState(false);
  
    return (
      <div className="border-b border-gray-200 py-4">
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full text-left text-lg font-medium text-[#6853a3] focus:outline-none flex items-center justify-between"
        >
          {question}
          <ChevronDown
            className={`h-5 w-5 transition-transform ${
              isOpen ? "rotate-180" : ""
            }`}
          />
        </button>
        {isOpen && (
          <div className="mt-2 text-gray-600" dangerouslySetInnerHTML={{ __html: answer }} />
        )}
      </div>
    );
  }

  function FAQCategory({ title, faqs }) {
    return (
      <div className="mb-8">
        <h3 className="text-2xl font-light mb-4">{title}</h3>
        <div className="space-y-4">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    );
  }

  useEffect(() => {
    const handleScroll = () => {
      setShowBackToTop(window.scrollY > 300);
      //const scrollPosition = window.scrollY + window.innerHeight / 2;
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSending(true); // Disable the button

    emailjs
      .sendForm(
        "service_xwnt5j3",
        "template_ug666dp",
        event.target,
        "EkzrLLh_o8C1vqFdj"
      )
      .then(
        () => {
          alert("Message sent successfully!");
          setFormData({ from_name: "", reply_to: "", message: "" });
          setIsSending(false); // Re-enable the button
          event.target.reset();
        },
        (error) => {
          console.error("Error:", error);
          alert("Failed to send message.");
          setIsSending(false); // Re-enable the button
        }
      );
  };

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <header className="px-4 lg:px-6 h-24 flex items-center justify-between bg-[#412a83] text-white sticky top-0 z-50">
        <div className="flex items-center justify-center  space-x-2">
          <Logo />
          <div className="flex items-center space-x-2">
            <TextLogo />
            </div>
        </div>
        <button
          className="lg:hidden text-white text-xl hover:bg-[primary] hover:text-white  p-2 rounded"
          onClick={toggleMenu}
          aria-label="Toggle menu"
        >
          ☰
        </button>
        <nav
        ref={menuRef}
      className={`fixed top-0 left-0 h-full w-64 bg-[rgba(65,42,131,0.96)] shadow-lg transform ${
        menuOpen ? "translate-x-0" : "-translate-x-full"
      } transition-transform duration-300 ease-in-out lg:static lg:translate-x-0 lg:flex lg:flex-row lg:bg-transparent lg:shadow-none lg:w-auto z-50`}
    >
          <ul className="flex flex-col lg:flex-row lg:gap-6 lg:items-center p-6 lg:p-0 space-y-4 lg:space-y-0">
            <li>
              <button
                onClick={() => scrollToSection(aboutRef)}
                className="text-sm font-medium hover:underline underline-offset-4"
              >
                About
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection(packagesRef)}
                className="text-sm font-medium hover:underline underline-offset-4"
              >
                Packages
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection(faqRef)}
                className="text-sm font-medium hover:underline underline-offset-4"
              >
                FAQs
              </button>
            </li>
            <li>
              <button
                onClick={() => scrollToSection(contactRef)}
                className="text-sm font-medium hover:underline underline-offset-4"
              >
                Contact
              </button>
            </li>
          </ul>
        </nav>
      </header>
      <main className="flex-1 flex flex-col items-center justify-center">
        <section
          ref={downloadRef}
          id="download"
          className="w-full py-12 md:py-24 lg:py-32 xl:py-5 flex items-center justify-center"
          style={{
            backgroundImage: `url(${BackgroundPic})`, // Use backticks for template literals and ${} for variables
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          
        >
          <div className="container mx-auto px-4 md:px-6">
            <div className="grid gap-6 lg:grid-cols-[1fr_400px] lg:gap-12 xl:grid-cols-[1fr_600px] items-center justify-center">
              <div className="flex flex-col justify-center space-y-4 text-center">
                <div className="space-y-2">
                  <h1 className="text-3xl font-light tracking-tighter sm:text-5xl xl:text-6xl/none text-white">
                    Your Trusted Tradesmen in Jamaica, Just a Tap Away!
                  </h1>
                  <p className="max-w-[500px] text-gray-200 md:text-xl mx-auto">
                    Find skilled professionals near you across Jamaica. Post jobs
                     effortlessly, accept bids from tradesmen.
                  </p>
                </div>
                <div className="flex flex-col gap-2 min-[400px]:flex-row  items-center justify-center" >
                  <a
                    href="#"
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-white text-primary hover:bg-gray-100 h-10 py-2 px-4"
                  >
                    <RiAppleFill className="h-6 w-6 text-[#412a83]" />
                    &nbsp;Download for iOS
                  </a>
                  <a
                    href="#"
                    className="inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-white text-primary hover:bg-gray-100 h-10 py-2 px-4"
                  >
                    <DiAndroid className="h-6 w-6 text-[#412a83]"/>
                     &nbsp;Download for Android
                  </a>
                </div>
              </div>
              <div className="flex items-center justify-center w-full h-full object-cover h-full w-full">
                <img
                  src={FeaturePic}
                  alt="App Screenshot"
                  className="object-cover h-full w-full"
                />
              
              </div>
            </div>
          </div>
        </section>
        <section
          ref={featuresRef}
          id="features"
          className="w-full py-12 md:py-24 lg:py-10 bg-[#c26c53] flex items-center justify-center"
        >
          <div className="container px-4 md:px-6">
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-5 gap-5 text-center">
              <div className="flex flex-col items-center">
                <CgSearchFound className="text-[#fff] h-12 w-12 mb-2" />
                <p className="text-md font-small text-[#fff]">Searching</p>
              </div>
              <div className="flex flex-col items-center">
                <MdOutlineAddTask className="text-[#fff] h-12 w-12 mb-2" />
                <p className="text-md font-small text-[#fff]">Post Jobs</p>
              </div>
              <div className="flex flex-col items-center">
                <LuCalendarCheck2 className="text-[#fff] h-12 w-12 mb-2" />
                <p className="text-md font-small text-[#fff]">Bid on Jobs</p>
              </div>
              <div className="flex flex-col items-center">
                <SlCallOut className="text-[#fff] h-12 w-12 mb-2" />
                <p className="text-md font-small text-[#fff]">
                  Call Tradesmen
                </p>
              </div>
              <div className="flex flex-col items-center">
                <FaRegCommentDots className="text-[#fff] h-12 w-12 mb-2" />
                <p className="text-md font-medium text-[#fff]">
                  Rate & Leave Reviews
                </p>
              </div>
            </div>
          </div>
        </section>
        <section
          ref={aboutRef}
          id="about"
          className="w-full py-12 md:py-24 lg:py-32 flex items-center justify-center"
        >
          <div className="container px-4 md:px-6 text-center">
            <h2 className="text-3xl font-light tracking-tighter sm:text-5xl mb-4">
              About Our App
            </h2>
            <p className="mx-auto max-w-[1500px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              Welcome to Tradesmen Factory, Jamaica&apos;s premier platform for
              connecting skilled professionals with those who need them most.
              Whether you&apos;re a homeowner seeking a quick repair or a
              business requiring specialized services, we make it simple,
              reliable, and efficient to find the right tradesman for the job.
              Our platform is committed to bridging the gap between exceptional
              tradesmen and clients, ensuring quality workmanship and
              satisfaction every time.{" "}
            </p>
            <br></br>
            <p className="mx-auto max-w-[1500px] text-gray-500 md:text-xl/relaxed lg:text-base/relaxed xl:text-xl/relaxed">
              At Tradesmen Factory, we believe that empowering tradesmen and
              clients is key to fostering growth and trust within our community.
              By providing a transparent and efficient platform, we connect
              skilled professionals with those who value their craft, building a
              network where quality workmanship meets exceptional service.
            </p>
          </div>
        </section>

        <section ref={packagesRef} className="w-full py-12 md:py-24 lg:py-32 bg-gray-100" id="packages">
        <div className="container px-4 md:px-6 mx-auto">
                                <h2 className="text-3xl font-weight tracking-tighter sm:text-4xl md:text-5xl text-center mb-8 text-primary">Tradesmen Packages</h2>
                                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                                    <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg">
                                        <h3 className="text-2xl font-bold text-primary mb-4">Silver</h3>
                                        <p className="text-4xl font-bold mb-6" style={{ color: "#412a83" }}>$2,500<span className="text-sm font-normal" style={{ color: "#FF5621" }}>/ 6 months</span></p>
                                        <ul className="mb-6 space-y-2">
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Basic Listing.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Bid on an unlimited jobs.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Upload up to 6 pictures.</span>
                                            </li>
                                        </ul>
                                        <a href="#" className="mt-auto inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-purple-900 text-white hover:bg-opacity-90 h-10 py-2 px-4">Choose Silver</a>
                                    </div>
                                    <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg border-2 border-primary">
                                        <h3 className="text-2xl font-bold text-primary mb-4">Gold</h3>
                                        <p className="text-4xl font-bold mb-6" style={{ color: "#412a83" }}>$3,500<span className="text-sm font-normal" style={{ color: "#FF5621" }}>/ 6month</span></p>
                                        <ul className="mb-6 space-y-2">
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Basic Listing.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Bid on an unlimited jobs.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Upload up to 9 pictures.</span>
                                            </li>
                                            
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Upload one 15-second video.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Link to your social media pages.</span>
                                            </li>
                                        </ul>
                                        <a href="#" className="mt-auto inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-purple-900 text-white hover:bg-opacity-90 h-10 py-2 px-4">Choose Gold</a>
                                    </div>
                                    <div className="flex flex-col p-6 bg-white rounded-lg shadow-lg">
                                        <h3 className="text-2xl font-bold text-primary mb-4">Diamond</h3>
                                        <p className="text-4xl font-bold mb-6" style={{ color: "#412a83" }}>$5,000<span className="text-sm font-normal" style={{ color: "#FF5621" }}>/ 6 months</span></p>
                                        <ul className="mb-6 space-y-2">
                                        <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Premium listing.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Bid on an unlimited jobs.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Upload up to 12 pictures.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Upload two 15-second videos.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Profile feature on home screen.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Short bio on profile.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Link to your social media pages.</span>
                                            </li>
                                            <li className="flex items-center">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#7C59DAFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="mr-2 h-5 w-5 text-primary"><polyline points="20 6 9 17 4 12"></polyline></svg>
                                                <span>Featured on our social media platforms.</span>
                                            </li>
                                        </ul>
                                        <a href="#" className="mt-auto inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-purple-900 text-white hover:bg-opacity-90 h-10 py-2 px-4">Choose Diamond</a>
                                    </div>
                                </div>
                            </div>
        </section>
        
        <section ref={faqRef} id="faqs" className="w-full py-12 md:py-24 lg:py-32 bg-white flex items-center justify-center" >
          <div className="container px-4 md:px-6">
      <h2 className="text-3xl font-light tracking-tighter sm:text-5xl text-center mb-4">
        Frequently Asked Questions
      </h2>
      <FAQCategory
        title="General Questions" 
        faqs={[
          {
            question: "1. What is Tradesmen Factory?",
            answer:
              "The Tradesmen Factory App connects you with skilled and verified tradesmen across Jamaica. You can post jobs, receive bids from contractors, plumbers, electricians, carpenters, and more, and search for tradesmen by skill, parish and community. Contact them directly through WhatsApp or by phone from their profile.",
          },
          {
            question: "2. How does the app work?",
            answer:
              "<ul><li>1. Download the app.</li> <li>2. Browse tradesmen by service type, parish, or community.</li> <li>3. View profiles, reviews, and ratings.</li> <li>4. Post a job and receive bids from tradesmen.</li>"
          },
          {
            question: "3. What types of tradesmen can I find?",
            answer: "You can find ac technicians, architects, carpenters, contractors, electricians, mechanics, masons, plumbers, painters, welders and other skilled professionals.",
          },
          {
            question: "4. How can I register as a tradesman?",
            answer: "Download the app, register as a tradesman, and complete the application process. Once verified, you'll be listed on the platform.",
          },
        ]}
      />
      <FAQCategory
        title="Using the App"
        faqs={[
          {
            question: "5. How do I pay for services?",
            answer: "Payment is made directly to the tradesman after the job is completed. Secure online payment options will be available in future updates.",
          },
          {
            question: "6. Can I cancel or withdraw a job or bid?",
            answer: "Yes, you can cancel a job or withdraw a bid through the app if needed.",
          },
          {
            question: "7. Can I leave a review for a tradesman?",
            answer:  "Yes, after your job is completed, you can leave a review and rating to help others make informed decisions. If you know of a particular tradesman, you can also leave a review for them.",
          },         
        ]}
      />
    </div>
        </section>

        <section ref={contactRef} id="contact" className="w-full py-12 md:py-32 lg:py-32 bg-white flex items-center justify-center bg-gray-200">
        <div className="container px-4 md:px-6 mx-auto">
                        <div className="grid gap-6 lg:grid-cols-2 lg:gap-12 items-center">
                            <div className="space-y-4">
                                <h2 className="text-3xl font-weight tracking-tighter sm:text-4xl md:text-5xl text-primary">Contact Us</h2>
                                <p className="text-gray-500 md:text-xl">
                                    Have questions or feedback? We'd love to hear from you. Fill out the form, and we'll get back to you as soon as possible.
                                </p>
                            </div>
                            <div className="space-y-4">
                                <form onSubmit={handleSubmit} className="space-y-4">
                                    <div>
                                        <label htmlFor="name" className="block text-sm font-medium text-gray-500">Name</label>
                                        <input 
                                            type="text" 
                                            id="name" 
                                            name="name" 
                                            required 
                                            className="mt-1 block w-full h-12 rounded-md border border-gray-300 shadow-sm focus:border-none"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="email" className="block text-sm font-medium text-gray-500">Email</label>
                                        <input 
                                            type="email" 
                                            id="email" 
                                            name="email" 
                                            required 
                                            className="mt-1 block w-full h-12 rounded-md border border-gray-300 shadow-sm focus:border-none"
                                        />
                                    </div>
                                    <div>
                                        <label htmlFor="message" className="block text-sm font-medium text-gray-500">Message</label>
                                        <textarea 
                                            id="message" 
                                            name="message" 
                                            rows="4" 
                                            required 
                                            className="mt-1 block w-full rounded-md border border-gray-300 shadow-sm focus:border-none"
                                        ></textarea>
                                    </div>
                                    <button 
                                        type="submit" 
                                        className="w-full inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background bg-purple-900 text-white hover:bg-primary/90 h-10 py-2 px-4"
                                        disabled={isSending}>                                    
                                    {isSending ? "Sending..." : "Send Message"}
                                    </button>
                                </form>
                                {formStatus && (
                                    <p className="text-center text-green-600">{formStatus}</p>
                                )}
                            </div>
                        </div>
                    </div>
        </section>

        {showBackToTop && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-4 right-4 p-2 bg-[#6853a3] text-white rounded-full shadow-lg hover:bg-[#574a89] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#c26c53]"
            aria-label="Back to top"
          >
            <ChevronUp className="h-6 w-6" />
          </button>
        )}
      </main>
      <footer className="flex flex-col gap-2 sm:flex-row py-6 w-full shrink-0 items-center justify-start px-4 md:px-6 border-t">
        <p className="text-xs text-gray-500">
          © {new Date().getFullYear()} Tradesmen Factory. All rights reserved.
        </p>
        <nav className="flex gap-2 sm:gap-2">
          <a
            href="https://web.facebook.com/tradesmenfactory"
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs hover:underline underline-offset-4"
          >
            <FaFacebook className="h-5 w-5" style={{ color: "#574a89" }} />
          </a>
          <a
            href="https://www.instagram.com/tradesmenfactory_app/"
            target="_blank"
            rel="noopener noreferrer"
            className="text-xs hover:underline underline-offset-4"
          >
            <PiInstagramLogoFill className="h-5 w-5" style={{ color: "#574a89" }} />
          </a>
        </nav>
        <p className="text-xs text-gray-500"><a href="">created by vinton.roberts</a></p>
      </footer>
    </div>
  );
}

export default AppLandingPageJsx;